exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-us-tsx": () => import("./../../../src/templates/about-us.tsx" /* webpackChunkName: "component---src-templates-about-us-tsx" */),
  "component---src-templates-academy-tsx": () => import("./../../../src/templates/academy.tsx" /* webpackChunkName: "component---src-templates-academy-tsx" */),
  "component---src-templates-amazon-shopper-report-2022-international-tsx": () => import("./../../../src/templates/amazon-shopper-report-2022-international.tsx" /* webpackChunkName: "component---src-templates-amazon-shopper-report-2022-international-tsx" */),
  "component---src-templates-amazon-shopper-report-2022-tsx": () => import("./../../../src/templates/amazon-shopper-report-2022.tsx" /* webpackChunkName: "component---src-templates-amazon-shopper-report-2022-tsx" */),
  "component---src-templates-amazon-shopper-report-2023-tsx": () => import("./../../../src/templates/amazon-shopper-report-2023.tsx" /* webpackChunkName: "component---src-templates-amazon-shopper-report-2023-tsx" */),
  "component---src-templates-amazon-shopper-report-2024-tsx": () => import("./../../../src/templates/amazon-shopper-report-2024.tsx" /* webpackChunkName: "component---src-templates-amazon-shopper-report-2024-tsx" */),
  "component---src-templates-careers-tsx": () => import("./../../../src/templates/careers.tsx" /* webpackChunkName: "component---src-templates-careers-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-data-privacy-tsx": () => import("./../../../src/templates/data-privacy.tsx" /* webpackChunkName: "component---src-templates-data-privacy-tsx" */),
  "component---src-templates-default-page-tsx": () => import("./../../../src/templates/default-page.tsx" /* webpackChunkName: "component---src-templates-default-page-tsx" */),
  "component---src-templates-home-tsx": () => import("./../../../src/templates/home.tsx" /* webpackChunkName: "component---src-templates-home-tsx" */),
  "component---src-templates-imprint-tsx": () => import("./../../../src/templates/imprint.tsx" /* webpackChunkName: "component---src-templates-imprint-tsx" */),
  "component---src-templates-news-item-tsx": () => import("./../../../src/templates/news-item.tsx" /* webpackChunkName: "component---src-templates-news-item-tsx" */),
  "component---src-templates-news-tsx": () => import("./../../../src/templates/news.tsx" /* webpackChunkName: "component---src-templates-news-tsx" */),
  "component---src-templates-remdash-tsx": () => import("./../../../src/templates/remdash.tsx" /* webpackChunkName: "component---src-templates-remdash-tsx" */),
  "component---src-templates-retail-media-tsx": () => import("./../../../src/templates/retail-media.tsx" /* webpackChunkName: "component---src-templates-retail-media-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */),
  "component---src-templates-whistleblowing-policy-tsx": () => import("./../../../src/templates/whistleblowing-policy.tsx" /* webpackChunkName: "component---src-templates-whistleblowing-policy-tsx" */)
}

